import React, { Component } from 'react';
import './TarrifsTv.css';
import somonTv from '../../../../images/tv/somonTv.png';
import oilaTv from '../../../../images/tv/oilaTv.png';
import menuIcon from '../../../../images/tv/menuIcon.png';
import requestTvImg from '../../../../images/tv/requestTvImg.png';
import { Button } from 'react-bootstrap';
class TarrifsTv extends Component {
    render() {
        return (
            <div>
                <div className='tarrifs'>
                    <div className='Tv'>
                        <div className='header'>
                            <h2>Тариф </h2>
                            <img src={somonTv} alt='img' />
                        </div>
                        <div className='content'>
                            <div className='options1'>
                                <label>ТВ каналы до</label>
                                <h4>400</h4>
                            </div>
                            <div className='options2'>
                                <label>Медиатека до</label>
                                <h4>300</h4>
                                <span>фильмов и сериалов</span>
                            </div>
                            <div className='options3'>
                                <h4>30</h4>
                                <span>сомони в месяц</span>
                            </div>
                            <div className='button'>
                                <Button> Подключить</Button>
                            </div>
                            <div className='footer'>
                                <a>
                                    <img src={menuIcon} alt='img' />
                                </a>
                                <span>Список каналов</span>
                            </div>

                        </div>
                    </div>
                    <div className='Tv'>
                        <div className='header'>
                            <h2>Тариф </h2>
                            <img src={oilaTv} alt='img' />
                        </div>
                        <div className='content'>
                            <div className='options1'>
                                <label>ТВ каналы до</label>
                                <h4>400</h4>
                            </div>
                            <div className='options2'>
                                <label>Медиатека до</label>
                                <h4>300</h4>
                                <span>фильмов и сериалов</span>
                            </div>
                            <div className='options3'>
                                <h4>30</h4>
                                <span>сомони в месяц</span>
                            </div>
                            <div className='button'>
                                <Button> Подключить</Button>
                            </div>
                            <div className='footer'>
                                <a>
                                    <img src={menuIcon} alt='img' />
                                </a>
                                <span>Список каналов</span>
                            </div>

                        </div>
                    </div>
                    <div className='requestText'>
                        <h3>Заполните заявку на подключение IPTV</h3>
                        <p>IPTV - это современная технология, позволяющая эффективно передавать телевизионный сигнал через сеть Интернет. </p>
                        <img src={requestTvImg} alt='img' />
                    </div>

                </div>

            </div>
        );
    }
}

export default TarrifsTv;