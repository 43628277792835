import React, { Component } from "react";
import "./SendRequest.css";
import { Container } from "react-bootstrap";
import women from "../../../../images/wimax/reqWoomen.png";
class SendRequest extends Component {
  render() {
    return (
      <div className="request">
        <Container>
          <h2 className="text-[30px] text-[#4a4a4a] font-bold pt-[20px]">
            Оставьте заявку на подключение
          </h2>
          <div className="flex gap-[30px] sm:flex-col sm:gap-[10px] items-center">
            <div className="flex flex-col">
                <span className="text-[20px] font-[400] text-left text-[#505050] pb-[5px] pl-[5px]">Имя</span>
                <input 
                  className="w-[340px] focus:outline-none focus:ring-cyan-700 focus:ring-[1.5px] h-[56px] rounded-[10px] pl-[10px]"
                  type="text"
                  placeholder="Ваша имя"
                />
            </div>
            <div className="flex flex-col">
                <span className="text-[20px] font-[400] text-left text-[#505050] pb-[5px] pl-[5px]">Телефон</span>
                <input 
                  className="w-[340px] focus:outline-none focus:ring-cyan-700 focus:ring-[1.5px] h-[56px] rounded-[10px] pl-[10px]"
                  type="number"
                  placeholder="+992"
                />
            </div>
            <div className="reqButton">
                <button className="h-[56px] w-[340px] rounded-[10px] bg-[#1E1F33] text-white mt-[30px] text-[20px]">
                  Отправить
                </button>
            </div>
            <div className="sm:hidden">
                <img src={women} alt='img' />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default SendRequest;
