import React, { useState } from "react";
import Logo from "../image/Logo.png";
import Icon from "../image/icon_first_section.png";
import { Container } from "react-bootstrap";

const FifeSection = () => {
  const [value, setValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [companyValue, setCompanyValue] = useState("");

  const handleShange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9) {
      setValue(inputValue);
    }
  };

  const handleChange = (setter) => (e) => {
    let inputValue = e.target.value;
    if (inputValue.length > 0) {
      inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    }
    setter(inputValue);
  };

  return (
    <div>
      <Container>
        <div className="grid grid-cols-2 sm:grid-cols-1 my-[100px] items-center">
          <div className="flex flex-col border-none w-[70%] sm:w-[90%] sm:ml-[20px] bg-white p-[20px] ml-[50px] items-center md:mr-8">
            <img src={Logo} alt="" className="mb-4" />
            <h1 className="text-2xl font-bold mb-2">Заполните заявку</h1>
            <p className="mb-4">Заполните заявку на подключение</p>
            <input 
              value={nameValue}
              onChange={handleChange(setNameValue)}
              type="text"
              placeholder="Как к Вам обращаться"
              className="border border-gray-300 rounded-[20px] p-2 mb-4 w-full"
            />
            <input
              type="number"
              maxLength="9"
              value={value}
              onChange={handleShange}
              placeholder="Введите номер телефона"
              className="border border-gray-300 rounded-[20px] p-2 mb-4 w-full"
            />
            <div className="flex items-center my-4">
              <hr className="flex-1 border-t border-gray-300" />
              <h1 className="px-2 text-lg font-semibold">
                Название компании важна
              </h1>
              <hr className="flex-1 border-t border-gray-300" />
            </div>
            <input
              value={companyValue}
              onChange={handleChange(setCompanyValue)}
              type="text"
              placeholder="Введите компанию"
              className="border border-gray-300 rounded-[20px] p-2 mb-4 w-full"
            />
            <button className="bg-blue-500 text-white rounded-[20px] p-2 w-full hover:bg-blue-600 transition">
              Отправить заявку
            </button>
          </div>
          <div className="sm:ml-[-160px]">
            <img src={Icon} alt="" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FifeSection;
