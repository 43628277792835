import React, { Component } from 'react';
import Evo from '../Tarrifs/Dushanbe/Lte/Evo';
import './AllTarrifsLte.css';
import { Container } from 'react-bootstrap';
import PaketLTE from '../Tarrifs/Dushanbe/Lte/PaketLTE';

class AllTarrifsLte extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCity: 'Душанбе',
            selectedTariff: 'Тарифы',
        };
    }
    handleCityChange = (event) => {
        const selectedCity = event.target.value;
        this.setState({
            selectedCity,
            selectedTariff: 'Тарифы',
        });
    };
    handleTariffChange = (tariff) => {
        this.setState({ selectedTariff: tariff });
    };
    getTariffMessage = () => {
        const { selectedCity, selectedTariff } = this.state;
        if (selectedTariff === 'Тарифы') {
            if (selectedCity === 'Душанбе') {
                return <Evo />;
            }
        } else if (selectedTariff === 'Пакеты') {
            if (selectedCity === 'Душанбе') {
                return < PaketLTE/>;
            }
        } else if (selectedTariff === 'Пакеты Start') {
            return `Привет, Пакеты Start для города ${selectedCity}`;
        } else if (selectedTariff === 'Пакеты Max') {
            return `Привет, Пакеты Max для города ${selectedCity}`;
        }
        return '';
    };
    render() {
        const { selectedCity, selectedTariff } = this.state;
        const tariffs = ['Тарифы','Пакеты'];
        const cities = ['Душанбе', 'Худжанд', 'Бохтар'];
        return (
            <div>
                 <Container>
                    <div style={{ marginTop: '20px', fontWeight: 'normal', marginBottom: '20px' }}>
                        Интернет / LTE / <span>{selectedTariff}</span>
                    </div>
                    <div>
                        <h2>Тарифные планы <span style={{ color: '#EE6969' }}>LTE</span></h2>
                    </div>
                    <div className='allSelect'>
                        <div>
                            {tariffs.map((tariff) => (
                                <button className='button'
                                    key={tariff}
                                    onClick={() => this.handleTariffChange(tariff)}
                                    style={{
                                        fontWeight: selectedTariff === tariff ? '400' : 'normal',
                                        borderBottom: selectedTariff === tariff ? '4px solid #EE6969' : 'none',
                                        color: selectedTariff === tariff ? '#EE6969' : '',
                                        margin: '5px',
                                    }}
                                >
                                    {tariff}
                                </button>
                            ))}
                        </div>
                        <div className='select'>
                            <label htmlFor="city-select">Город подключения: </label>
                            <select
                                id="city-select"
                                value={selectedCity}
                                onChange={this.handleCityChange}
                                style={{ margin: '5px' }}
                            >
                                {cities.map((city) => (
                                    <option key={city} value={city}>
                                        {city}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {selectedTariff && (
                        <p>{this.getTariffMessage()}</p>
                    )}
                </Container>
            </div>
        );
    }
}

export default AllTarrifsLte;