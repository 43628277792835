import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import TarrifsTv from '../Tarrifs/Dushanbe/TV/TarrifsTv';
import TarrifsTextTv from '../Tarrifs/Dushanbe/TV/TarrifsTextTv';

class AllTarrifsTv extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedCity: 'Душанбе',
			selectedTariff: 'Тарифы',
		};
	}

	handleCityChange = (event) => {
		const selectedCity = event.target.value;
		this.setState({
			selectedCity,
			selectedTariff: 'Тарифы',
		});
	};

	handleTariffChange = (tariff) => {
		this.setState({ selectedTariff: tariff });
	};

	getTariffMessage = () => {
		const { selectedCity, selectedTariff } = this.state;
		if (selectedTariff === 'Тарифы') {
			if (selectedCity === 'Душанбе') {
				return <TarrifsTv/>;
			}

		} else if (selectedTariff === 'Об услуге') {
			if (selectedCity === 'Душанбе') {
				return < TarrifsTextTv/>;
			}
		}
		return '';
	};

	render() {
		const { selectedCity, selectedTariff } = this.state;
		const tariffs = ['Тарифы','Об услуге'];

		return (
			<div>
				<Container>
					<div style={{ marginTop: '20px', fontWeight: 'normal', marginBottom: '20px' }}>
						Интернет / LTE / <span>{selectedTariff}</span>
					</div>


					<div className='tvH2'>
						<h2>Тарифы для подключения </h2>
					</div>
					<div className='allSelect'>
						<div>
							{tariffs.map((tariff) => (
								<button className='button'
									key={tariff}
									onClick={() => this.handleTariffChange(tariff)}
									style={{
										fontWeight: selectedTariff === tariff ? '400' : 'normal',
										borderBottom: selectedTariff === tariff ? '4px solid #EE6969' : 'none',
										color: selectedTariff === tariff ? '#EE6969' : '',
										margin: '5px',
									}}
								>
									{tariff}
								</button>
							))}
						</div>
					</div>
					{selectedTariff && (
						<p>{this.getTariffMessage()}</p>
					)}


				</Container>
			</div>
		);
	}
}

export default AllTarrifsTv;
