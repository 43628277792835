import React, { Component } from "react";
import Tarrifs from "../Components/Tarrifs/Tarrifs";
import fon from "../images/tarrifs/tarrifs.png";
import "./Homeinternet.css";
import AllTarrifs from "../Components/AllTarrifs/AllTarrifs";
import MapConnect from "../Components/MapConnect/MapConnect";
import Equipment from "../Components/Equipment/Equipment";
import CallMee from "../Components/CallMee/CallMee";
import Faq from "../Components/Faq/Faq";

class Homeinternet extends Component {
  render() {
    return (
      <div>
        <div className="fon">
          <div className="absolute text-white pl-[15%] font-[400] pt-[5%]">
            <h1>
              <span className="text-[68px] font-[800]">Интернет</span> <br /> для вашего дома
            </h1>
          </div>
          <img src={fon} alt="fon" />
        </div>
        <AllTarrifs />
        <MapConnect />
        <Equipment />
        <CallMee />
        <Faq />
      </div>
    );
  }
}

export default Homeinternet;
