import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import './AllTarrifsNgn.css';
import TarrifNgn from '../Tarrifs/Dushanbe/Ngn/TarrifNgn';
import BasicTarrisNgn from '../Tarrifs/Dushanbe/Ngn/BasicTarrisNgn';
import Faq from '../Faq/Faq';
class AllTarrifsNgn extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedCity: 'Душанбе',
			selectedTariff: 'Тариф Простой',
		};
	}

	handleCityChange = (event) => {
		const selectedCity = event.target.value;
		this.setState({
			selectedCity,
			selectedTariff: 'Тариф Простой', 
		});
	};

	handleTariffChange = (tariff) => {
		this.setState({ selectedTariff: tariff });
	};

	getTariffMessage = () => {
		const { selectedCity, selectedTariff } = this.state;
		if (selectedTariff === 'Тариф Простой') {

			return <TarrifNgn/>;


		} else if (selectedTariff === 'Тариф Основной') {
			return < BasicTarrisNgn/>;
		} else if (selectedTariff === 'Messenger') {
			return `Вот это Тарифы Messenger `;
		}
		return '';
	};

	render() {
		const { selectedCity, selectedTariff } = this.state;
		const tariffs = ['Тариф Простой', 'Тариф Основной','Messenger'];
		return (
			<div>
			<Container>
				<div style={{ marginTop: '20px', fontWeight: 'normal', marginBottom: '20px' }}>
					Интернет / LTE / <span>{selectedTariff}</span>
				</div>
				<div>
					<h2>Тарифы для подключения </h2>
				</div>
				<div className='allSelect'>
					<div>
						{tariffs.map((tariff) => (
							<button className='button'
								key={tariff}
								onClick={() => this.handleTariffChange(tariff)}
								style={{
									fontWeight: selectedTariff === tariff ? '400' : 'normal',
									borderBottom: selectedTariff === tariff ? '4px solid #EE6969' : 'none',
									color: selectedTariff === tariff ? '#EE6969' : '',
									margin: '5px',
								}}
							>
								{tariff}
							</button>
						))}
					</div>
				</div>
				{selectedTariff && (
					<p>{this.getTariffMessage()}</p>
				)}
			<div className='callMee' style={{backgroundColor:'#00A551',borderRadius:'10px'}}>
				<Container>
					<div className='callBlock'>
						<div className='callText'>
							<p> Введите телефон, и мы перезвоним прямо сейчас!</p>
						</div>
						<div>
							<div className='callInput'>
								<input type='number' className='focus:outline-none focus:ring-[1.5px] focus:ring-gray-100' placeholder='+992' />
								<button>
									Жду звонка
								</button>
							</div>
							<div className='callFooter'>
								<p>Наши специалисты подберут, привезут и установят все необходимое</p>
							</div>
						</div>
					</div>
				</Container>
				</div>
			</Container>
			<Faq/>
		</div>
			);
	}
}

export default AllTarrifsNgn;
