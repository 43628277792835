import React, { Component } from 'react';
import './Domain.css';
import { Container } from 'react-bootstrap';
import DomainTp from '../Components/Tarrifs/Dushanbe/DomainTP/DomainTp';

class Domain extends Component {
    render() {
        return (
            <div className='myDomain'>
                <div className='fonDomain'>
                    <Container>
                    <h1>Домен</h1>

                    </Container>
                </div>
                <DomainTp/>
            </div>
        );
    }
}

export default Domain;