import React from 'react';
import { useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../images/logo.png';
import login from '../images/Login.png';
import opticRouter from '../images/header/header.png';
import './Header.css';

function NavScrollExample() {
    const location = useLocation();

    // Проверяем, содержится ли в текущем URL слово "business"
    const isBusinessMenuVisible = location.pathname.includes('/business');

    return (
        <Navbar collapseOnSelect expand="lg" className="custom-navbar">
            <Container>
                <Navbar.Brand href="/home">
                    <img
                        src={logo}
                        height="50"
                        width="128"
                        className="d-inline-block align-top"
                        alt="logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
                        {isBusinessMenuVisible ? (
                            <NavDropdown title="Для бизнеса" id="navbarScrollingDropdown">
                                <div>
                                    <NavDropdown.ItemText className="headerTitle">Интернет</NavDropdown.ItemText>
                                    <Nav.Link href="/business/tariffs" className="text">Тарифы</Nav.Link>
                                    <NavDropdown.Item className="text" href="#">Оборудование</NavDropdown.Item>
                                </div>
                            </NavDropdown>

                        ) : (
                            <NavDropdown title="Интернет" id="navbarScrollingDropdown">
                                <div className="headerFlex">
                                    <div>
                                        <NavDropdown.ItemText className="headerTitle">Домашный интернет</NavDropdown.ItemText>
                                        <Nav.Link href="/Homeinternet" className="text">Тарифы</Nav.Link>
                                        <NavDropdown.Item className="text" href="#">Оборудование</NavDropdown.Item>
                                        <NavDropdown.Item className="text" href="#action5">Годовые Пакеты</NavDropdown.Item>
                                        <NavDropdown.Item className="text" href="#action5">Турбо скорость</NavDropdown.Item>
                                    </div>
                                    <div>
                                        <NavDropdown.ItemText className="headerTitle">Технология WiMAX</NavDropdown.ItemText>
                                        <NavDropdown.Item className="text" href="/wimax">Тарифы</NavDropdown.Item>
                                        <NavDropdown.Item className="text" href="/wimax#12">Оборудование</NavDropdown.Item>
                                        <NavDropdown.Item className="text" href="#action5">Пакеты</NavDropdown.Item>
                                    </div>
                                    <div>
                                        <NavDropdown.ItemText className="headerTitle">Технология LTE</NavDropdown.ItemText>
                                        <NavDropdown.Item className="text" href="/lte">Тарифы</NavDropdown.Item>
                                        <NavDropdown.Item className="text" href="/lte#13">Оборудование</NavDropdown.Item>
                                        <NavDropdown.Item className="text" href="#action5">Пакеты</NavDropdown.Item>
                                    </div>
                                    <div>
                                        <img src={opticRouter} alt="optic router" />
                                    </div>
                                </div>
                            </NavDropdown>
                        )}
                        {isBusinessMenuVisible ? (
                            <>
                                <Nav.Link href="/business/ngn">Телефония</Nav.Link>
                                <Nav.Link href="/business/vps">Виртуальный сервер</Nav.Link>
                                <Nav.Link href="/business/domain">Домен</Nav.Link>
                                <Nav.Link href="/business/hosting">Хостинг</Nav.Link>
                            </>
                        ) : (

                            <><Nav.Link href="/tv">Телевидение</Nav.Link>
                                <Nav.Link href="/ngn">Телефония</Nav.Link>
                                <NavDropdown title="Доп.услуги" id="navbarScrollingDropdown">
                                    <NavDropdown.Item href="/domain">Домен</NavDropdown.Item>
                                    <NavDropdown.Item href="/hosting">Хостинг</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="/vps">Виртуальный сервер VPS</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/promotion">Акции</Nav.Link></>
                        )}
                    </Nav>
                    <Form className="d-flex">
                        <Form.Control
                            type="search"
                            placeholder="Search"
                            className="me-2"
                            aria-label="Search"
                        />
                    </Form>
                    <Nav.Link className="youAccount" href="https://arm.babilon-t.com" target="_blank">Личный кабинет</Nav.Link>
                    <img src={login} height="24.68" width="24" className="d-inline-block align-top login" alt="logo" />
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavScrollExample;
