import React, { Component } from 'react';
import './TarrifNgn.css';

class TarrifNgn extends Component {
    render() {
        const data = {
            connectionFee: 100,
            numberAssignmentFee: 10,
            servicePrepayment: 5,
            subscriptionFee: 0,
            totalInitialPayment: 115,
            callRates: {
                vpnGroup: { voice: 0.01, video: 0.08 },
                cyberPhone: { voice: 0.02, video: 0.192 },
                mobileOperators: { voice: 0.06, video: 0.24 },
                fixedOperators: { voice: 0.06, video: 0.24 },
            },
        };
        return (
            <div className='tarrifsNgn'>
                <table className="tariff-table">
                    <thead>
                        <tr>
                            <th colSpan="1" className="header-cell">Подключение к сети NGN (проводной)</th>
                            <th colSpan="3" className="value-cell">{data.connectionFee} сомони</th>
                        </tr>
                        <tr>
                            <th colSpan="2" className="header-cell">Присвоение обычного номера</th>
                            <th colSpan="2" className="value-cell">{data.numberAssignmentFee} сомони</th>
                        </tr>
                        <tr>
                            <th colSpan="2" className="header-cell">Предоплата услуг связи</th>
                            <th colSpan="2" className="value-cell">{data.servicePrepayment} сомони</th>
                        </tr>
                        <tr>
                            <th colSpan="2" className="header-cell">Абонентская плата</th>
                            <th colSpan="2" className="value-cell">{data.subscriptionFee} сомони</th>
                        </tr>
                        <tr>
                            <th colSpan="2" className="header-cell total-header">Сумма общего первоначального платежа</th>
                            <th colSpan="2" className="value-cell total-value">{data.totalInitialPayment} сомони</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th colSpan="2" className="call-type-header">Тип вызова</th>
                            <th className="call-type-header">Голосовой звонок</th>
                            <th className="call-type-header">Видео звонок</th>
                        </tr>
                        <tr>
                            <td colSpan="2" className="call-type">Исходящие вызовы внутри VPN группы</td>
                            <td className="value-cell">{data.callRates.vpnGroup.voice} сомони</td>
                            <td className="value-cell">{data.callRates.vpnGroup.video} сомони</td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="call-type">Исходящие вызовы внутри сети CyberPhone "Вавилон-Т"</td>
                            <td className="value-cell">{data.callRates.cyberPhone.voice} сомони</td>
                            <td className="value-cell">{data.callRates.cyberPhone.video} сомони</td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="call-type">Исходящие вызовы на мобильных операторов</td>
                            <td className="value-cell">{data.callRates.mobileOperators.voice} сомони</td>
                            <td className="value-cell">{data.callRates.mobileOperators.video} сомони</td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="call-type">Исходящие вызовы на фиксированных операторов</td>
                            <td className="value-cell">{data.callRates.fixedOperators.voice} сомони</td>
                            <td className="value-cell">{data.callRates.fixedOperators.video} сомони</td>
                        </tr>
                    </tbody>
                </table>

                <ol>
                    <li>Междугородние звонки внутри сети CyberPhone ООО «Вавилон-Т», тарифицируются как исходящие звонки внутри сети, независимо от географического положения абонента.</li>
                    <li>Междугородние звонки на сети других операторов тарифицируются как местные исходящие вызовы</li>
                    <li>Начисления Абонентской платы производится каждое 1-е число календарного месяца.</li>
                    <li>Не тарифицируемый порог в тарифном плане «Основной» - 1 минута (кроме международных вызовов).</li>
                    <span> ** Все цены указаны с учетом НДС.</span>
                </ol>
            </div>
        );
    }
}

export default TarrifNgn;