import React, { Component } from 'react';
import './Vps.css';
import { Container } from 'react-bootstrap';
import VpsTp from '../Components/Tarrifs/Dushanbe/VpsTP/VpsTp';

class Vps extends Component {
    render() {
        return (
            <div className='myVps'>
                <div className='fonVps'>
                    <Container>
                        <p className='sm:text-[30px] sm:mt-[260px] text-[60px] font-[700] text-[868b9b]'>Виртуальный <br /> сервер VPS</p>
                    </Container>
                </div>
                <VpsTp />
            </div>
        );
    }
}

export default Vps;