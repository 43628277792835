import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import './PaketLTE.css';


class PaketLTE extends Component {

    componentDidMount() {
        this.updateAllProgress();
    }

    updateProgress = (id, current, max) => {
        const circle = document.querySelector(`.progress-ring__circle-${id}`);
        const radius = circle.r.baseVal.value;
        const circumference = 2 * Math.PI * radius;

        circle.style.strokeDasharray = `${circumference} ${circumference}`;
        circle.style.strokeDashoffset = `${circumference}`;

        const offset = circumference - (current / max) * circumference;
        setTimeout(() => {
            circle.style.transition = 'stroke-dashoffset 1s ease-in-out';
            circle.style.strokeDashoffset = offset;
        }, 100);
    }

    updateAllProgress = () => {
        const tariffs = this.getTariffs();
        tariffs.forEach((tariff, index) => {
            this.updateProgress(index, tariff.current, tariff.max);
        });
    }
    getTariffs = () => {
        return [
            { name: 'Пакет LTE 1 ', current: 4, max: 30, speed: '1 МБит/с', limit: '0,186', price: '43,37', borderColor: '#979797' },
            { name: 'Пакет LTE 2 ', current: 9, max: 30, speed: '1 МБит/с', limit: '0,186', price: '43,37', borderColor: '#979797' },

        ];
    }
    
    render() {
        const tariffs = this.getTariffs();

        return (
            <div>
                <div className='paketLTE'>
                    {tariffs.map((tariff, index) => (
                        <div className='paket'>
                            <div className='paketText'>
                                <h3>{tariff.name} </h3>
                                <div className="cyber-start-usage">
                                    <div className="cyber-start-circle">
                                        <svg className="progress-ring" width="120" height="120">
                                            <circle
                                                className={`progress-ring__circle-bg progress-ring__circle-bg-${index}`}
                                                stroke="black"
                                                strokeWidth="10"
                                                fill="#ffffff"
                                                r="52"
                                                cx="60"
                                                cy="60"
                                            />
                                            <circle
                                                className={`progress-ring__circle progress-ring__circle-${index}`}
                                                stroke="#FF5555"
                                                strokeWidth="10"
                                                fill="transparent"
                                                r="52"
                                                cx="60"
                                                cy="60"
                                            />
                                        </svg>
                                        <div className="cyber-start-text">{tariff.current} ГБ</div>
                                    </div>
                                </div>
                            </div>
                            <div className='paketPrice'>
                                <h4>{tariff.price}</h4> <span>сомони</span>
                            </div>
                            <div className='paketButton'>
                                <Button>Выбрать</Button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='Note'>
                    <p>Примечание:</p>
                    <ol>
                        <li>Пакет активируется толька после окончания трафика в основном тарифном плане.</li>
                        <li>Пакет можно подключить несколько раз в течение месяца.</li>
                        <li>По окончании оплаченного трафика в пакете, тарификация производится согласно Вашего тарифного плана.</li>

                    </ol>
                </div>
            </div>
        );
    }
}



export default PaketLTE;