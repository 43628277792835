import React, { useState } from "react";
import Icon1 from "../image/icon_first_section.png";
import { Container } from "react-bootstrap";

const FirstSection = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div className="bg-[#4ab7fbc1] sm:h-[365px] sm:mb-[330px] h-[500px] w-full">
      <Container>
        <div className="flex items-center">
          <p className="text-center text-[60px] sm:text-[18px] mb-4">Интернет для Бизнеса по Оптике</p>
          <img
            src={Icon1}
            alt=""
            className="w-full max-w-[930px] sm:max-w-[250px] mb-4"
          />
        </div>
        <div className="flex justify-between sm:grid sm:grid-cols-1 sm:gap-[10px]">
          {[
            { title: "Безлимитный Интернет", subtitle: "для вашего бизнеса" },
            { title: "Беспроводной LTE", subtitle: "Беспроводной интернет" },
            { title: "VPS сервера", subtitle: "Lorem Ipsum simply" },
            { title: "WiFi Роутер", subtitle: "Топ роутер для бизнеса" },
          ].map((item, index) => (
            <div
              key={index}
              className={`flex items-center p-4 rounded-lg border transition-colors duration-300 
                ${
                  hoveredIndex === index
                    ? "bg-blue-500 text-white"
                    : "bg-white text-black"
                } 
                shadow-md cursor-pointer`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div
                className={`flex items-center justify-center w-8 h-8 rounded-full border-2 
                  ${
                    hoveredIndex === index ? "border-white" : "border-blue-500"
                  } 
                  transition-colors duration-300`}
              >
                {hoveredIndex === index ? "✔" : `0${index + 1}`}
              </div>
              <div className="ml-3">
                <p className="text-lg font-semibold">{item.title}</p>
                <p className="text-sm text-gray-600">{item.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default FirstSection;
