// AdditionalService.js
import React from 'react';
import './AdditionalService.css';

class AdditionalService extends React.Component {
  handleCheckboxChange = () => {
    const { name, onChange, value } = this.props;
    onChange(name, !value);
  }

  handleQuantityChange = (e) => {
    const { name, onChange, value } = this.props;
    onChange(name, value, parseInt(e.target.value) || 0);
  }

  render() {
    const { label, description, value, quantity } = this.props;
    return (
      <div className="additional-service">
        <input
          type="checkbox"
          checked={value}
          onChange={this.handleCheckboxChange}
        />
        <div className='block-text'>
          <label>
            {label}
          </label>
          <span className="service-description">{description}</span>
        </div>

        <input
          type="number"
          value={quantity}
          onChange={this.handleQuantityChange}
          min={0}
          disabled={!value}
        />
        <span>Кол-во серверов</span>
      </div>
    );
  }
}

export default AdditionalService;