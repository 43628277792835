
import React, { Component } from 'react';

class TarrifsTextTv extends Component {
    render() {
        return (
            <div>
                {/* Hello world word world world world world world world world world wrodl */}
            </div>
        );
    }
}

export default TarrifsTextTv;