import React, { Component } from "react";
class Promotion extends Component {
  render() {
    return (
      <div className="px-[8%] py-6 bg-gray-100 min-h-screen">
        <h1 className="text-4xl font-bold text-left mb-8 text-blue-600">
          Акции
        </h1>
        <div className="bg-blue-500 text-white p-6 rounded-lg shadow-lg mb-8">
          <h2 className="text-3xl font-semibold">Супер скидка 50%</h2>
          <p className="mt-2 text-lg">
            Подключите интернет прямо сейчас и получите скидку 50% на первый
            месяц!
          </p>
          <button className="mt-4 bg-white text-blue-500 px-6 py-2 rounded-lg hover:bg-gray-200">
            Узнать больше
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-1 gap-6">
          <div className="bg-white p-4 shadow-md rounded-lg">
            <h3 className="text-xl font-semibold text-blue-500">
              Годовой пакет
            </h3>
            <p className="mt-2">
              Оплатите сразу за год и получите два месяца бесплатно!
            </p>
          </div>
          <div className="bg-white p-4 shadow-md rounded-lg">
            <h3 className="text-xl font-semibold text-blue-500">
              Турбо скорость
            </h3>
            <p className="mt-2">
              Увеличьте скорость интернета до 100 Мбит/с без переплат.
            </p>
          </div>
          <div className="bg-white p-4 shadow-md rounded-lg">
            <h3 className="text-xl font-semibold text-blue-500">
              Бонусные баллы
            </h3>
            <p className="mt-2">
              За каждую оплату получайте баллы и обменивайте их на подарки!
            </p>
          </div>
        </div>
        <div className="mt-10 text-left">
          <h2 className="text-2xl font-bold mb-4">Не упустите шанс!</h2>
          <p className="text-lg mb-6">
            Подключитесь к нашим тарифам и воспользуйтесь специальными
            предложениями прямо сейчас.
          </p>
          <button className="bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700">
            Подключиться
          </button>
        </div>
      </div>
    );
  }
}

export default Promotion;
