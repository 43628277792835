import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carouselbox from '../Components/Carouselbox';
import Services from '../Components/Services';
import Tarrifs from '../Components/Tarrifs/Tarrifs';
import ConnectStages from '../Components/ConnectStages/ConnectStages';
import Equipment from '../Components/Equipment/Equipment';
import Payment from '../Components/Payment/Payment';
import News from '../Components/News/News';
import Iptv from '../Components/Iptv/Iptv';
import Footer from '../Components/Footer/Footer';

class Home extends Component {
    render() {
        return (
            <div>
              <Carouselbox/>
              <Services/>
              <Tarrifs/>
              <ConnectStages/>
              <Equipment/>
              <Payment/>
              <News/>
              <Iptv/>
            </div>
        );
    }
}

export default Home;