import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Optom.css";
import sotSet from "../../../images/sotSet.png";
import SotSS from "../../../images/sotSS.png";

const Optom = () => {
  const navigate = useNavigate();
  const [selectedPrices, setSelectedPrices] = useState({
    "Optom S": "12",
    "Optom M": "12",
    "Optom L": "12",
    "Optom XL": "12",
    "Optom XXL": "12",
  });

  const handleSelectTariff = (tariffName) => {
    navigate(`/connection/${tariffName}`);
  };

  const prices = {
    "Optom M": {
      12: { total: 2760, monthly: 2210 },
      6: { total: 1380, monthly: 1170 },
    },
    "Optom L": {
      12: { total: 4080, monthly: 3265 },
      6: { total: 2040, monthly: 1735 },
    },
    "Optom XL": {
      12: { total: 5400, monthly: 4320 },
      6: { total: 2700, monthly: 2295 },
      3: { total: 1350, monthly: 1215 },
    },
    "Optom XXL": {
      12: { total: 6720, monthly: 5375 },
      6: { total: 3360, monthly: 2855 },
      3: { total: 1680, monthly: 1510 },
    },
  };

  const handlePriceChange = (tariffName, value) => {
    setSelectedPrices((prev) => ({
      ...prev,
      [tariffName]: value,
    }));
  };

  return (
    <Container>
      <div className="blockTarif">
        <div className="card">
          <p>Optom S</p>
          <div className="img"></div>
          <div className="options">
            <ol>
              <li>
                <span className="span">Скорость</span>
                <h4>1 МБить/с</h4>
              </li>
              <li>
                <span className="span">Интернет Трафик </span>
                <h4>Безлимитный</h4>
              </li>
              <li>
                <span className="span">Скорость доступа на</span>
                <span className="img">
                  <img src={sotSet} />
                </span>
                <br />
                <span className="img1">
                  <img src={SotSS} />
                </span>
                <br />
                <h4>10 Мбит/с</h4>
              </li>
            </ol>
            <div className="mb-[33px] pt-[5px]">Цена за 12 месяцев</div>
            <div className="footer">
              <div className="priceOptom">
                <h2>
                  <span>1440</span>
                </h2>
                <h3>1150</h3>
                <span className="span">
                  сом <br /> мес
                </span>
              </div>
              <Button
                className="Button"
                variant="primary"
                onClick={() => handleSelectTariff("Optom S")}
              >
                Выбрать
              </Button>
              <a href="#">Подробное</a>
            </div>
          </div>
        </div>

        <div className="card">
          <p>Optom M</p>
          <div className="img"></div>
          <div className="options">
            <ol>
              <li>
                <span className="span">Скорость</span>
                <h4>1 МБить/с</h4>
              </li>
              <li>
                <span className="span">Интернет Трафик </span>
                <h4>Безлимитный</h4>
              </li>
              <li>
                <span className="span">Скорость доступа на</span>
                <span className="img">
                  <img src={sotSet} />
                </span>
                <br />
                <span className="img1">
                  <img src={SotSS} />
                </span>
                <br />
                <h4>10 Мбит/с</h4>
              </li>
            </ol>
            <select
              value={selectedPrices["Optom M"]}
              onChange={(e) => handlePriceChange("Optom M", e.target.value)}
            >
              <option value="12">Цена за 12 месяцев</option>
              <option value="6">Цена за 6 месяцев</option>
            </select>
            <div className="footer">
              <div className="priceOptom">
                <h2>
                  <span>
                    {prices["Optom M"][selectedPrices["Optom M"]].total}
                  </span>
                </h2>
                <h3>{prices["Optom M"][selectedPrices["Optom M"]].monthly}</h3>
                <span className="span">
                  сом <br /> мес
                </span>
              </div>
              <Button
                className="Button"
                variant="primary"
                onClick={() => handleSelectTariff("Optom M")}
              >
                Выбрать
              </Button>
              <a href="/connection">Подробное</a>
            </div>
          </div>
        </div>

        <div className="card">
          <p>Optom L</p>
          <div className="img"></div>
          <div className="options">
            <ol>
              <li>
                <span className="span">Скорость</span>
                <h4>1 МБить/с</h4>
              </li>
              <li>
                <span className="span">Интернет Трафик </span>
                <h4>Безлимитный</h4>
              </li>
              <li>
                <span className="span">Скорость доступа на</span>
                <span className="img">
                  <img src={sotSet} />
                </span>
                <br />
                <span className="img1">
                  <img src={SotSS} />
                </span>
                <br />
                <h4>10 Мбит/с</h4>
              </li>
            </ol>
            <select
              value={selectedPrices["Optom L"]}
              onChange={(e) => handlePriceChange("Optom L", e.target.value)}
            >
              <option value="12">Цена за 12 месяцев</option>
              <option value="6">Цена за 6 месяцев</option>
            </select>
            <div className="footer">
              <div className="priceOptom">
                <h2>
                  <span>
                    {prices["Optom L"][selectedPrices["Optom L"]].total}
                  </span>
                </h2>
                <h3>{prices["Optom L"][selectedPrices["Optom L"]].monthly}</h3>
                <span className="span">
                  сом <br /> мес
                </span>
              </div>
              <Button
                className="Button"
                variant="primary"
                onClick={() => handleSelectTariff("Optom L")}
              >
                Выбрать
              </Button>
              <a href="#">Подробное</a>
            </div>
          </div>
        </div>

        <div className="card recom">
          <p>Optom XL</p>
          <div className="img"></div>
          <div className="options">
            <ol>
              <li>
                <span className="span">Скорость</span>
                <h4>1 МБить/с</h4>
              </li>
              <li>
                <span className="span">Интернет Трафик </span>
                <h4>Безлимитный</h4>
              </li>
              <li>
                <span className="span">Скорость доступа на</span>
                <span className="img">
                  <img src={sotSet} alt="sotlet" />
                </span>
                <br />
                <span className="img1">
                  <img src={SotSS} />
                </span>
                <br />
                <h4>10 Мбит/с</h4>
              </li>
            </ol>
            <select
            className="text-[black]"
              value={selectedPrices["Optom XL"]}
              onChange={(e) => handlePriceChange("Optom XL", e.target.value)}
            >
              <option value="12">Цена за 12 месяцев</option>
              <option value="6">Цена за 6 месяцев</option>
              <option value="3">Цена за 3 месяца</option>
            </select>
            <div className="footer">
              <div className="priceOptom">
                <h2>
                  <span>
                    {prices["Optom XL"][selectedPrices["Optom XL"]].total}
                  </span>
                </h2>
                <h3 className="text-[black]">
                  {prices["Optom XL"][selectedPrices["Optom XL"]].monthly}
                </h3>
                <span className="span">
                  сом <br /> мес
                </span>
              </div>
              <Button
                className="Button"
                variant="primary"
                onClick={() => handleSelectTariff("Optom XL")}
              >
                Выбрать
              </Button>
              <a href="#">Подробное</a>
            </div>
          </div>
        </div>

        <div className="card">
          <p>Optom XXL</p>
          <div className="img"></div>
          <div className="options">
            <ol>
              <li>
                <span className="span">Скорость</span>
                <h4>1 МБить/с</h4>
              </li>
              <li>
                <span className="span">Интернет Трафик </span>
                <h4>Безлимитный</h4>
              </li>
              <li>
                <span className="span">Скорость доступа на</span>
                <span className="img">
                  <img src={sotSet} alt="sotlet" />
                </span>
                <br />
                <span className="img1">
                  <img src={SotSS} />
                </span>
                <br />
                <h4>10 Мбит/с</h4>
              </li>
            </ol>
            <select
              value={selectedPrices["Optom XXL"]}
              onChange={(e) => handlePriceChange("Optom XXL", e.target.value)}
            >
              <option value="12">Цена за 12 месяцев</option>
              <option value="6">Цена за 6 месяцев</option>
              <option value="3">Цена за 3 месяца</option>
            </select>
            <div className="footer">
              <div className="priceOptom">
                <h2>
                  <span>
                    {prices["Optom XXL"][selectedPrices["Optom XXL"]].total}
                  </span>
                </h2>
                <h3>
                  {prices["Optom XXL"][selectedPrices["Optom XXL"]].monthly}
                </h3>
                <span className="span">
                  сом <br /> мес
                </span>
              </div>
              <Button
                className="Button"
                variant="primary"
                onClick={() => handleSelectTariff("Optom XXL")}
              >
                Выбрать
              </Button>
              <a href="#">Подробное</a>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Optom;
