import React from 'react';
import './ResourceSlider.css';
import ram from '../../images/vps/ram.png';
import cpu from '../../images/vps/cpu.png';
import hdd from '../../images/vps/hdd.png';
import ssd from '../../images/vps/ssd.png';

class ResourceSlider extends React.Component {
  componentDidMount() {
    this.updateRangeBackground();
  }

  componentDidUpdate() {
    this.updateRangeBackground();
  }

  updateRangeBackground = () => {
    const { value, min, max } = this.props;
    const percentage = ((value - min) / (max - min)) * 100;
    this.rangeInput.style.background = `linear-gradient(to right, #4285f4 0%, #4285f4 ${percentage}%, #e0e0e0 ${percentage}%, #e0e0e0 100%)`;
  }

  handleSliderChange = (e) => {
    const { name, onChange, quantity } = this.props;
    onChange(name, parseInt(e.target.value), quantity);
  }

  handleQuantityChange = (e) => {
    const { name, onChange, value } = this.props;
    const newQuantity = parseInt(e.target.value) || 1;
    onChange(name, value, newQuantity);
  }

  render() {
    const { name, value, min, max, label, description, quantity, unitPrice, img } = this.props;
    const totalPrice = value * quantity * unitPrice;

    return (
      <div className="resource-slider">
        <div className="slider-label">
          <span className='slider-title'>{label}</span>
          <div className='card-option'>
            <img className='img' src={img === 'ram' ? ram : img === 'cpu' ? cpu : img === 'hdd' ? hdd : img === 'ssd' ? ssd : 'GB'} alt='img' />
            <div className='slider-card'>
              <span className="slider-description-title">{name}</span>
            <span className="slider-description">{description}</span>
            </div>

          </div>
        </div>
        <div className="range-container">
          <div className="range-labels">
            <span>{min}</span>
            <span>{Math.round((max - min) / 4 + min)}</span>
            <span>{Math.round((max - min) / 2 + min)}</span>
            <span>{Math.round(3 * (max - min) / 4 + min)}</span>
            <span>{max}</span>
          </div>
          <input
            type="range"
            name={name}
            value={value}
            onChange={this.handleSliderChange}
            min={min}
            max={max}
            className="custom-range"
            ref={(input) => this.rangeInput = input}
          />
        </div>
        <div className="slider-value">
          {/* <span>{value} {name === 'vCPU' ? 'ядер' : 'GB'}</span> */}
          <input
            type="number"
            value={quantity}
            onChange={this.handleQuantityChange}
            min={1}
            max={10}
            className="quantity-input"
          />
          {/* <span>{totalPrice} сомони</span> */}
          <span> шт.</span>
        </div>
      </div>
    );
  }
}

export default ResourceSlider;