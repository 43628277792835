import React from "react";
import Icon1 from "../image/basket ball.png";
import Icon2 from "../image/monitor.png";
import Icon3 from "../image/user-circle.png";
import Icon4 from "../image/filter 01.png";
import { Container } from "react-bootstrap";

const SecondSection = () => {
  return (
    <Container>
      <div>
        <h1 className="text-center w-full mt-[80px] text-2xl font-bold mb-4">Преимущества</h1>
        <div className="grid grid-cols-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <div className="flex flex-col items-center p-4 m-2 max-w-xs">
            <img src={Icon1} alt="" className="w-16 h-16 mb-2" />
            <h1 className="text-lg font-semibold">Высокая скорость</h1>
            <p className="text-center">
              Безлимитный Интернет для бизнеса на скорости до 1 Гбит/с.
            </p>
            <a href="#!" className="text-blue-500 mt-2">
              Подключить
            </a>
          </div>
          <div className="flex flex-col items-center p-4 m-2 max-w-xs">
            <img src={Icon2} alt="" className="w-16 h-16 mb-2" />
            <h1 className="text-lg font-semibold">Мониторинг</h1>
            <p className="text-center">
              Автоматическая диагностика предотвращает технические сбои
            </p>
          </div>
          <div className="flex flex-col items-center p-4 m-2 max-w-xs">
            <img src={Icon3} alt="" className="w-16 h-16 mb-2" />
            <h1 className="text-lg font-semibold">Личный кабинет</h1>
            <p className="text-center">
              Узнавайте и пополняйте баланс, получайте счета, меняйте настройки,
              смотрите статистику.
            </p>
          </div>
          <div className="flex flex-col items-center p-4 m-2 max-w-xs">
            <img src={Icon4} alt="" className="w-16 h-16 mb-2" />
            <h1 className="text-lg font-semibold">Техподдержка</h1>
            <p className="text-center">
              24/7 Горячая линия или ваш персональный менеджер ответят на все
              вопросы
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SecondSection;
