import React, { Component } from "react";
import "./Turbo.css";
import turboS from "../../../images/turbo/TurboS.png";
import turboM from "../../../images/turbo/TurboM.png";
import turboL from "../../../images/turbo/TurboL.png";
import turboXL from "../../../images/turbo/TurboXL.png";

class Turbo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTimes: {
        S: "1 час",
        M: "1 час",
        L: "3 часа",
        XL: "1 час",
      },
    };
  }

  handleTimeChange = (type, time) => {
    this.setState((prevState) => ({
      selectedTimes: { ...prevState.selectedTimes, [type]: time },
    }));
  };

  calculatePrice = (time) => {
    switch (time) {
      case "1 час":
        return 10;
      case "3 часа":
        return 20;
      case "6 часов":
        return 30;
      case "12 часов":
        return 50;
      default:
        return 10;
    }
  };

  renderOption = (title, image, type) => {
    const { selectedTimes } = this.state;
    const times = ["1 час", "3 часа", "6 часов", "12 часов"];
    const price = this.calculatePrice(selectedTimes[type]);

    return (
      <div className="option">
        <h2 className="title">{title}</h2>
        <div className="speed-container">
          <img src={image} alt={title} />
        </div>
        <div className="main">
          <div className="time-options">
            <h3 className="title-time-options">Выберите время</h3>
            {times.map((time) => (
              <div className="flex" key={time}>
                <span>{time}</span>
                <label className="switch">
                  <input
                    type="radio"
                    value={time}
                    checked={selectedTimes[type] === time}
                    onChange={() => this.handleTimeChange(type, time)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="price">
          <span>{price}</span> сомони
        </div>
        <button className="select-button">Выбрать</button>
        <a className="footer-a" href="#/">
          Подробное
        </a>
      </div>
    );
  };

  render() {
    return (
      <div className="Turbo">
        <div className="container">
          {this.renderOption("Турбо S", turboS, "S")}
          {this.renderOption("Турбо M", turboM, "M")}
          {this.renderOption("Турбо L", turboL, "L")}
          {this.renderOption("Турбо XL", turboXL, "XL")}
        </div>
      </div>
    );
  }
}

export default Turbo;
