import React, { Component } from 'react';
import './Hosting.css';
import { Container } from 'react-bootstrap';
import HostingTp from '../Components/Tarrifs/Dushanbe/HostingTP/HostingTp';
class Hosting extends Component {
    render() {
        return (
            <div className='myHosting'>
                <div className='fonHosting'>
                    <Container>
                    <h1>Хостинг</h1>

                    </Container>
                </div>

                <HostingTp/>
            </div>
        );
    }
}

export default Hosting;