import React, { Component } from 'react';
// import Dushanbe from '../Tarrifs/Dushanbe';
import { Container } from 'react-bootstrap';
import './AllTarrifsWimax.css';
import Dushanbe from '../Tarrifs/Dushanbe/Dushanbe';
import CyberStart from '../Tarrifs/Dushanbe/Wimax/CyberStart';
import CyberMax from '../Tarrifs/Dushanbe/Wimax/CyberMax';
import PaketStart from '../Tarrifs/Dushanbe/Wimax/PaketStart';
import PaketMax from '../Tarrifs/Dushanbe/Wimax/PaketMax';

class AllTarrifsWimax extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedCity: 'Душанбе',
			selectedTariff: 'Тарифы CyberStart',
		};
	}

	handleCityChange = (event) => {
		const selectedCity = event.target.value;
		this.setState({
			selectedCity,
			selectedTariff: 'Тарифы CyberStart',
		});
	};

	handleTariffChange = (tariff) => {
		this.setState({ selectedTariff: tariff });
	};

	getTariffMessage = () => {
		const { selectedCity, selectedTariff } = this.state;
		if (selectedTariff === 'Тарифы CyberStart') {
			if (selectedCity === 'Душанбе') {
				return <CyberStart />;
			}

		} else if (selectedTariff === 'Тарифы CyberMax') {
			if (selectedCity === 'Душанбе') {
				return <CyberMax />;
			}
		} else if (selectedTariff === 'Пакеты Start') {
			if (selectedCity === 'Душанбе') {
				return <PaketStart/>;
			}
		} else if (selectedTariff === 'Пакеты Max') {
			if (selectedCity === 'Душанбе') {
				return <PaketMax/>;
			}
		}
		return '';
	};

	render() {
		const { selectedCity, selectedTariff } = this.state;
		const tariffs = ['Тарифы CyberStart', 'Тарифы CyberMax', 'Пакеты Start', 'Пакеты Max'];
		const cities = ['Душанбе', 'Худжанд', 'Бохтар'];


		return (
			<>
				<Container>
					<div style={{ marginTop: '20px', fontWeight: 'normal', marginBottom: '20px' }}>
						Интернет / Wimax / <span>{selectedTariff}</span>
					</div>


					<div>
						<h2>Тарифные планы <span style={{ color: '#EE6969' }}>Wimax</span></h2>
					</div>
					<div className='allSelect'>
						<div>
							{tariffs.map((tariff) => (
								<button className='button'
									key={tariff}
									onClick={() => this.handleTariffChange(tariff)}
									style={{
										fontWeight: selectedTariff === tariff ? '400' : 'normal',
										borderBottom: selectedTariff === tariff ? '4px solid #EE6969' : 'none',
										color: selectedTariff === tariff ? '#EE6969' : '',
										margin: '5px',
									}}
								>
									{tariff}
								</button>
							))}
						</div>
						<div className='select'>
							<label htmlFor="city-select">Город подключения: </label>
							<select
								id="city-select"
								value={selectedCity}
								onChange={this.handleCityChange}
								style={{ margin: '5px' }}
							>
								{cities.map((city) => (
									<option key={city} value={city}>
										{city}
									</option>
								))}
							</select>
						</div>

					</div>
					{selectedTariff && (
						<p>{this.getTariffMessage()}</p>
					)}


				</Container>

			</>
		);
	}
}

export default AllTarrifsWimax;
