    import React, { Component } from 'react';
    import './Ngn.css';
    import { Container } from 'react-bootstrap';
    import AllTarrifsNgn from '../Components/AllTarrifsNgn/AllTarrifsNgn';
    class Ngn extends Component {
        render() {
            return (
                <div className='myNgn'>
                    <div className='fonNgn'>
                        <Container>
                            <h1>CyberPhone - Телефония</h1>
                        <h4>для вашего дома</h4>
                    </Container>
                </div>
                    <AllTarrifsNgn/>
            </div>
        );
    }
}

export default Ngn;