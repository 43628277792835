import React, { Component } from 'react';
import Evo from '../Components/Tarrifs/Dushanbe/Lte/Evo';
import './Lte.css';
import { Container } from 'react-bootstrap';
import logo from '../images/LTE/LTE.png';
import router from '../images/LTE/routerLTE.png';
import AllTarrifsLte from '../Components/AllTarrifsLte/AllTarrifsLte';
import SendRequest from '../Components/Tarrifs/Dushanbe/Wimax/SendRequest';
import Equipment from '../Components/Equipment/Equipment';
class Lte extends Component {
    render() {
        return (
            <div className='lte'>
                <div className='fonHeader'>
                    <Container>
                        <div className='flex'>
                            <img className='logo' src={logo} alt='img' />
                            <div className='headerText'>
                                <h4>Беспроводной</h4>
                                <h1>Иинтернет</h1>
                            </div>
                            <img className='imgRouter' src={router} alt='img' />
                        </div>

                    </Container>
                </div>
       <AllTarrifsLte/>       
       <SendRequest/>
       <div id='13'>
        <Equipment/>
        </div> 
                {/* <Evo /> */}
            </div>
        );
    }
}

export default Lte;