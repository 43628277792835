import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import axios from "axios";

import { URL } from "../url";

const Carouselbox = () => {
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await axios.get(`${URL}/api/swiper-data`);
        setSlides(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchSlides();
  }, []);

  const toggleExpand = (index) => {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const getDescription = (description, index) => {
    const words = description.split(" ");
    if (words.length <= 25 || expanded[index]) {
      return description;
    }
    return `${words.slice(0, 25).join(" ")} ... `;
  };

  return (
    <div className="h-[70vh] sm:h-[50vh] md:h-[60vh] mt-[30px]">
      <style>
        {`
          @media (max-width: 640px) {
            .swiper-button-next,
            .swiper-button-prev {
              display: none !important;
            }
          }
          .swiper-button-prev {
          margin-left: 4% !important;
          color: "red" !important;
          }
          .swiper-button-next {
          margin-right: 4% !important;
          color: "red" !important;
          }
        `}
      </style>

      <Swiper
        pagination={{ type: "fraction" }}
        navigation={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper h-full"
      >
        {loading ? (
          <div className="text-center">Loading...</div>
        ) : (
          slides.map((el, index) => (
            <SwiperSlide key={index} className="relative">
              <img
                src={`${URL}${el.image}`}
                className="absolute top-0 left-0 object-fill w-full h-full"
                alt={el.title}
              />
              <div className="absolute top-[65%] left-0 transform sm:w-[90%] sm:top-[78%] md:top-[85%] md:w-[90%] md:pl-[45px] sm:pl-[45px] -translate-y-1/2 pl-[8%] pr-[8%] z-10 w-[50%]">
                <h2 className="text-[35px] sm:text-[17px] md:text-[22px] font-bold text-black">
                  {el.title}
                </h2>
                <p className="text-[15px] pt-[10px] sm:text-[12px] md:text-[12px] md:pt-[0] sm:pt-[0] text-start font-bold text-black">
                  {getDescription(el.description, index)}
                  {el.description.split(" ").length > 25 && (
                    <button
                      onClick={() => toggleExpand(index)}
                      className="text-blue-500 ml-2 hover:text-blue-800"
                    >
                      {expanded[index] ? "Less" : "More"}
                    </button>
                  )}
                </p>
                <div
                  style={{ display: el.showbtn ? "block" : "none" }}
                  className="mt-[30px] sm:mt-[-10px] md:mt-[-10px]"
                >
                  <button className="bg-[#FF6E0E] pl-[20px] pr-[20px] sm:p-[5px] pt-[10px] pb-[10px] text-white rounded-md">
                    Подробнее
                  </button>
                </div>
              </div>
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </div>
  );
};

export default Carouselbox;
