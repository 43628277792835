import React, { Component } from "react";
import "./HostingTp.css";
import { Container } from "react-bootstrap";
import cloud from "../../../../images/hosting/cloudIcon.png";
import cloudHover from "../../../../images/hosting/cloudiconHover.png";
import check from "../../../../images/hosting/checkIcon.png";
import checkHover from "../../../../images/hosting/checkHover.png";
import user from "../../../../images/hosting/user-plus.png";
import domen from "../../../../images/hosting/domen.png";
import ssl from "../../../../images/hosting/ssl.png";
import women from "../../../../images/wimax/reqWoomen.png";
import Faq from "../../../Faq/Faq";
import SendRequest from "../Wimax/SendRequest";

class HostingTp extends Component {
  render() {
    const plans = [
      {
        size: "20 MB",
        plan: "Начальный",
        features: ["FTP", "PHP", "MySql", "Нет"],
        price: "30,65",
      },
      {
        size: "20 MB",
        plan: "Начальный",
        features: ["FTP", "PHP", "MySql", "Нет"],
        price: "30,65",
      },
      {
        size: "20 MB",
        plan: "Начальный",
        features: ["FTP", "PHP", "MySql", "Нет"],
        price: "30,65",
      },
      {
        size: "20 MB",
        plan: "Начальный",
        features: ["FTP", "PHP", "MySql", "Нет"],
        price: "30,65",
      },
      {
        size: "20 MB",
        plan: "Начальный",
        features: ["FTP", "PHP", "MySql", "Нет"],
        price: "30,65",
      },
    ];
    return (
      <div>
        <Container>
          <div
            style={{
              marginTop: "20px",
              fontWeight: "normal",
              marginBottom: "20px",
            }}
          >
            Интернет / Доп Услуги / Хостинг
          </div>

          <h3>Хостинг для сайта</h3>

          <div className="tarrifHost"></div>
          <div className="flex bg-white my-[30px] sm:flex-col md:flex-wrap lg:flex-wrap items-center">
            <div className="flex justify-center sm:flex-col">
              {plans.map((plan, index) => (
                <div
                  className="pricing-card bg-[white] rounded-[15px] shadow-md shadow-gray-500 m-[10px] p-[30px] text-start sm:w-[48vh]"
                  key={index}
                >
                  <div className="icon-container">
                    <i className="icon">
                      <img src={cloud} alt="img" />{" "}
                    </i>
                    <i className="hover-icon">
                      {" "}
                      <img src={cloudHover} alt="img" />
                    </i>
                  </div>
                  <div className="details">
                    <h3>{plan.size}</h3>
                    <p>{plan.plan}</p>
                    <ul>
                      {plan.features.map((feature, idx) => (
                        <li key={idx}>
                          <span className="icon">
                            {" "}
                            <img src={check} alt="img" />
                          </span>
                          <span className="hover-icon">
                            {" "}
                            <img src={checkHover} alt="img" />
                          </span>
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <div className="price">
                      <p>
                        {plan.price} <br /> <span>сомони/месяц</span>{" "}
                      </p>
                    </div>
                    <button>Подключить</button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="dopUslugHost ">
            <p className="font-bold text-[30px] text-[#181054]">
              Дополнительные услуги
            </p>
            <div className="grid grid-cols-3 sm:gap-[30px] sm:grid-cols-1">
              <div className="flex flex-col h-[280px] gap-[10px] border-none items-center justify-center w-[100%]">
                <div className="img">
                  <img className="h-[80px] w-[80px]" src={user} alt="img" />
                </div>

                <h3 className="text-[25px] font-[500] text-[#181059] text-center">
                  Администрирование хостинга
                </h3>
                <label className="text-[18px] text5-[#181059] text-center pb-[20px] pt-[5px]">
                  Доверьте настройку вашего хостинга специалистам RU-CENTER
                </label>

                <a href="#/" className="font-bold text-[25px] text-[#448fff]">
                  Заказать
                </a>
              </div>

              <div className="flex flex-col h-[280px] gap-[10px] border-none items-center justify-center w-[100%]">
                <div>
                  <img className="h-[80px] w-[80px]" src={domen} alt="img" />
                </div>

                <h3 className="text-[25px] font-[500] text-[#181059] text-center">
                  Настройка домена
                </h3>
                <label lassName="text-[18px] text5-[#181059] text-center pb-[20px] pt-[5px]">
                  Выполним все необходимые операции для работы домена за вас
                </label>

                <a href="#/" className="font-bold text-[25px] text-[#448fff]">
                  Заказать
                </a>
              </div>

              <div className="flex flex-col h-[280px] gap-[10px] border-none items-center justify-center w-[100%]">
                <div>
                  <img className="h-[80px] w-[80px]" src={ssl} alt="img" />
                </div>

                <h3 className="text-[25px] font-[500] text-[#181059] text-center">
                  SSL-сертификаты
                </h3>
                <label lassName="text-[18px] text5-[#181059] text-center pb-[20px] pt-[5px]">
                  Обеспечьте безопасное соединение пользователей с вашим сайтом
                </label>

                <a href="#/" className="font-bold text-[25px] text-[#448fff]">
                  Заказать
                </a>
              </div>
            </div>
          </div>
        </Container>

        <SendRequest />
        <Faq />
      </div>
    );
  }
}

export default HostingTp;
