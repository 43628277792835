import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './topNav.css';
import Myip from './Myip';

function TextLinkNavbar({ toggleMenu }) {
  return (
    <Navbar bg="light" data-bs-theme="light" className='main-navbar'>
      <Container>
        <Nav className="me-auto">
          <Nav.Link href="/home">Для дома</Nav.Link>
          <Nav.Link  href="/business">Для бизнеса</Nav.Link>
        </Nav>
        <Nav className="justify-content-end">
          <Nav.Link href="#home">Taj</Nav.Link>
          <span>|</span>
          <Nav.Link href="#features">Rus</Nav.Link>
          <span className='youIp'><Myip/></span>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default TextLinkNavbar;
