import React, { Component } from 'react';
import './Tv.css';
import { Button, Container } from 'react-bootstrap';

import kubok from '../images/tv/kubok.png';
import AllTarrifsTv from '../Components/AllTarrifsTv/AllTarrifsTv';
import Payment from '../Components/Payment/Payment';
import Equipment from '../Components/Equipment/Equipment';
import Faq from '../Components/Faq/Faq';

class Tv extends Component {
    render() {
        return (
            <>
             <div className='fontTv'>
                <Container>
                    <div className='textContent'>
                        <h3>IP Телевидение</h3>
                        <div className='list'>
                            <ol>
                                <li>Подключаем в удобное для вас время;</li>
                                <li>Подключение Бесплатно;</li>
                                <li>Более 400 телеканалов, более 50 из которых в HD-качестве;</li>
                                <li>Более 30 бесплатных сервисов для наших абонентов.</li>
                            </ol>
                        </div>
                        <div className='button'><Button>Выбрать тариф</Button></div>
                    </div>
                    <div className='fonKubok'>
                        <img src={kubok} alt='kubok'/>
                    </div>
                </Container>


            </div>
            <AllTarrifsTv/>
            <Payment/>
            <Equipment/>
            <Faq/>
            </>
           
        );
    }
}

export default Tv;