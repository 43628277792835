import React, { Component } from "react";
import ResourceSlider from "./ResourceSlider";
import AdditionalService from "./AdditionalService";
import "./ServerCalculator.css";

class ServerCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vCPU: { value: 10, quantity: 1 },
      vRAM: { value: 20, quantity: 1 },
      vHDD: { value: 200, quantity: 1 },
      vSSD: { value: 50, quantity: 1 },
      windowsLicenses: { value: true, quantity: 2 },
      backupService: { value: true, quantity: 1 },
      antivirusService: { value: true, quantity: 1 },
      techSupport: { value: true, quantity: 1 },
      subscriptionMonths: 6,
      price: 0,
      prewPrice: 0,
    };
  }

  componentDidMount() {
    this.calculatePrice();
  }

  handleResourceChange = (name, value, quantity) => {
    this.setState(
      (prevState) => ({
        [name]: { ...prevState[name], value, quantity },
      }),
      this.calculatePrice
    );
  };

  handleAdditionalServiceChange = (
    name,
    value,
    quantity = this.state[name].quantity
  ) => {
    this.setState(
      (prevState) => ({
        [name]: { ...prevState[name], value, quantity },
      }),
      this.calculatePrice
    );
  };

  handleSubscriptionChange = (e) => {
    this.setState(
      { subscriptionMonths: parseInt(e.target.value) },
      this.calculatePrice
    );
  };

  calculatePrice = () => {
    const {
      vCPU,
      vRAM,
      vHDD,
      vSSD,
      windowsLicenses,
      backupService,
      antivirusService,
      techSupport,
      subscriptionMonths,
    } = this.state;

    let price =
      vCPU.value * vCPU.quantity * 20 +
      vRAM.value * vRAM.quantity * 20 +
      vHDD.value * vHDD.quantity * 0.5 +
      vSSD.value * vSSD.quantity * 1 +
      (windowsLicenses.value ? windowsLicenses.quantity * 100 : 0) +
      (backupService.value ? backupService.quantity * 100 : 0) +
      (antivirusService.value ? antivirusService.quantity * 100 : 0) +
      (techSupport.value ? techSupport.quantity * 100 : 0);
    console.log("price", price);
    let prewPrice = price;
    if (subscriptionMonths === 6) {
      price *= 0.9; // 10% скидка
    } else if (subscriptionMonths === 12) {
      price *= 0.8; // 20% скидка
    }
    console.log("pric1", price);
    this.setState({ price: Math.round(price) });
    this.setState({ prewPrice: Math.round(prewPrice) });
  };

  render() {
    return (
      <div className="server-calculator">
        <div className="w-[800px]">
          <div className="resources">
            <ResourceSlider
              name="vCPU"
              value={this.state.vCPU.value}
              quantity={this.state.vCPU.quantity}
              onChange={this.handleResourceChange}
              min={1}
              max={30}
              label="Процессоры"
              description="1 vCPU=20сом/мес."
              unitPrice={20}
              img={"cpu"}
            />
            <ResourceSlider
              name="vRAM"
              value={this.state.vRAM.value}
              quantity={this.state.vRAM.quantity}
              onChange={this.handleResourceChange}
              min={1}
              max={90}
              label="Оперативная память"
              description="1 GB=20сом/мес."
              unitPrice={20}
              img={"ram"}
            />
            <ResourceSlider
              name="vHDD"
              value={this.state.vHDD.value}
              quantity={this.state.vHDD.quantity}
              onChange={this.handleResourceChange}
              min={1}
              max={500}
              label="Диск СТАНДАРТ - 100 IOPS"
              description="1 GB=0.5сом/мес."
              unitPrice={0.5}
              img={"hdd"}
            />
            <ResourceSlider
              name="vSSD"
              value={this.state.vSSD.value}
              quantity={this.state.vSSD.quantity}
              onChange={this.handleResourceChange}
              min={1}
              max={500}
              label="Диск УЛЬТРА - 3000 IOPS"
              description="1 GB=1сом/мес."
              unitPrice={1}
              img={"ssd"}
            />
          </div>

          <div className="additional-services">
            <p className="text-[30px] font-bold sm:text-[22px]">
              Добавить дополнительные услуги
            </p>
            <AdditionalService
              name="windowsLicenses"
              label="Лицензии Windows Server"
              description="1 сервер = 100 сом/ мес."
              value={this.state.windowsLicenses.value}
              quantity={this.state.windowsLicenses.quantity}
              onChange={this.handleAdditionalServiceChange}
            />
            <AdditionalService
              name="backupService"
              label="Резервное копирование"
              description="1 сервер = 100 сом/ мес."
              value={this.state.backupService.value}
              quantity={this.state.backupService.quantity}
              onChange={this.handleAdditionalServiceChange}
            />
            <AdditionalService
              name="antivirusService"
              label="Антивирус"
              description="1 сервер = 100 сом/ мес."
              value={this.state.antivirusService.value}
              quantity={this.state.antivirusService.quantity}
              onChange={this.handleAdditionalServiceChange}
            />
            <AdditionalService
              name="techSupport"
              label="Тех. поддержка 24/7"
              description="1 сервер = 100 сом/ мес."
              value={this.state.techSupport.value}
              quantity={this.state.techSupport.quantity}
              onChange={this.handleAdditionalServiceChange}
            />
          </div>
        </div>

        <div className="pricing bg-[#f0f0f0] p-[30px] h-[690px] sm:w-[100%] rounded-[10px] w-[480px]">
          <div>
            <div className="pricing-text">
              <h2 className="mt-[0] text-[60px] sm:text-[35px] font-[600] text-left text-[#ffffff] relative pt-[30px]">
                {this.state.price}
                <span> сом./{this.state.subscriptionMonths} мес.</span>
              </h2>
              <h2 className="mt-[0] text-[60px] sm:text-[35px] font-[600] text-left text-[#ffffff80] relative pb-[40px] pt-[0px]">
                {this.state.prewPrice}
                <span> сом./{this.state.subscriptionMonths} мес.</span>
              </h2>
            </div>
            <p className="change-discount">Выберите со скидкой</p>
            <div className="range-labels">
              <span>{1} мес.</span>
              <span>{Math.round((3 * (12 - 1)) / 7 + 1)} мес.</span>
              <span>{12} мес.</span>
            </div>
            <input
              type="range"
              value={this.state.subscriptionMonths}
              onChange={this.handleSubscriptionChange}
              min={1}
              max={12}
              className="custom-range"
            />
            <div className="range-labels">
              <span></span>
              <span>-10% </span>
              <span>-20%</span>
            </div>
            <div className="footer-text">
              <p>Стоимость включает НДС.</p>
              <p>
                В стоимость включены канал доступа в Интернет и стандартная
                защита от DDoS
              </p>
            </div>
          </div>

          <button className="bg-[#4285f4] hover:bg-[#3367d6] text-white sm:w-[100%] w-[400px] h-[75px] gap-[0] rounded-[16px] text-[15px] font-[500] text-center border-none mt-[80px]">
            Получить предложение
          </button>
        </div>
      </div>
    );
  }
}

export default ServerCalculator;
