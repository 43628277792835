import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import { URL } from "../../url";

const Equipment = () => {
  const [eqpt, setEqpt] = useState(null);

  useEffect(() => {
    const getEqpt = async () => {
      try {
        const response = await axios.get(`${URL}/api/eqpt`);
        console.log("Полученные данные оборудования:", response.data);
        setEqpt(response.data);
      } catch (error) {
        console.error(
          "Ошибка при получении данных оборудования",
          error.response ? error.response.data : error.message
        );
      }
    };

    getEqpt();
  }, []);

  return (
    <Container>
      <h2 className="pt-[20px]">Оборудование</h2>
      <div className="grid grid-cols-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 pt-[20px] gap-[10px]">
        {eqpt?.map((el) => {
          return (
            <div
              style={{
                border: el.id === 3 ? "2px solid #495AFF" : "1px solid #b3b2b2",
              }}
              className="bg-white p-[10px] hover:ring-2 transition duration-300 ease-in-out hover:ring-[#495AFF] group rounded-[20px] mb-[30px]"
            >
              <div className="flex items-center justify-center">
                <img
                  src={`${URL}${el.image}`}
                  alt={el.modele}
                  className=" h-[180px] w-[180px]"
                />
              </div>
              <span className="subTitle">{el.modele}</span>
              <p className="font-[800] text-[20px]">{el.type}</p>
              <div className="flex items-center  justify-between">
                <p className="text-[14px]">Скорость соединения</p>
                <div className="flex items-center">
                  {Array.from({ length: 5 }).map((_, index) => (
                    <span
                      key={index}
                      className="h-[2.5px] w-[2.5px] bg-[black] rounded-full mt-[-5px] mx-[2px]"
                    ></span>
                  ))}
                </div>
                <p className="font-[600]">до {el.bandwidth} Мбит/с</p>
              </div>
              <div className="flex items-center justify-between">
                <p>Площадь покрытия</p>
                <div className="flex items-center">
                  {Array.from({ length: 9 }).map((_, index) => (
                    <span
                      key={index}
                      className="h-[2.5px] w-[2.5px] bg-[black] rounded-full mt-[-5px] mx-[2px]"
                    ></span>
                  ))}
                </div>
                <p className="font-[600]">до {el.coverage} кв.м</p>
              </div>
              <div className="flex items-center justify-between">
                <p>Поддерживает</p>
                <div className="flex items-center">
                  {Array.from({ length: 8 }).map((_, index) => (
                    <span
                      key={index}
                      className="h-[2.5px] w-[2.5px] bg-[black] rounded-full mt-[-5px] mx-[2px]"
                    ></span>
                  ))}
                </div>
                <p className="font-[600]">до {el.supports} устройств</p>
              </div>
              <div className="flex items-center mt-[90px] mb-[20px] justify-between">
                <div>
                  <div className="flex items-end gap-[4px]">
                    <p className="font-[600] text-[25px]">{el.price}</p>
                    <p className="pb-[3px] font-[600]">сом</p>
                  </div>
                </div>
                <div>
                  {el.id === 3 ? (
                    <button className="h-[45px] w-[170px] shadow-xl shadow-[#b3b2b2] rounded-2xl text-white bg-[#495AFF]">
                      Заказать
                    </button>
                  ) : (
                    <button className="h-[45px] w-[170px] shadow-xl shadow-[#b3b2b2] rounded-2xl text-white transition duration-300 ease-in-out group-hover:bg-[#495AFF] bg-[black]">
                      Заказать
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default Equipment;
