import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import "./Payment.css";

import viza from "../../images/payment/viza.png";
import cards from "../../images/payment/card.png";
import card1 from "../../images/payment/card1.png";

class Payment extends Component {
  render() {
    return (
      <div className="Payment">
        <Container>
          <div className="myPay">
            <div className="textPay">
              <h1>Онлайн Оплата</h1>
              <span>услуги Компании</span>
            </div>
            <div className="cardPay">
              <img className="card1" alt="h" src={cards} />
            </div>
            <div className="buttonPay">
              <img alt="h" className="img" src={viza} />
              <Button className="button">Пополнить</Button>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default Payment;
