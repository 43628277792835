import React from "react";
import First from "../image/1121.png";
import Second from "../image/2212.png";
import { Container } from "react-bootstrap";

const SixSection = () => {
  return (
    <Container>
      <div className="my-[50px] flex flex-col items-center">
        <h1 className="text-2xl font-bold mb-5 text-center">
          Домин и хостинг для сайта
        </h1>
        <div className="grid grid-cols-2 w-full sm:grid-cols-1 gap-5">
          <div className="bg-[#F8F8F8] shadow-lg rounded-lg p-5 flex items-center">
            <div>
              <h2 className="text-xl font-semibold mb-2">Домин</h2>
              <p className="mb-4 w-[200px] text-gray-700">
                Размещайте сайты на быстром хостинге с удобной панелью
                управления
              </p>
              <div className="flex mt-[100px] z-50 space-x-4">
                <button className="bg-blue-600 text-white py-2 px-4 rounded-full transition duration-200 hover:bg-blue-700">
                  Подробное
                </button>
                <button className="bg-[white] border hover:text-white border-blue-600 text-blue-600 py-2 px-4 rounded-full transition duration-200 hover:bg-blue-700">
                  Оформить
                </button>
              </div>
            </div>
            <img
              src={First}
              alt="Домин"
              className="mt-[60px] sm:hidden w-[300px] h-[300px] absolute ml-[250px]"
            />
          </div>
          <div className="bg-[#F8F8F8] shadow-lg rounded-lg p-5 flex items-center">
            <div>
            <h2 className="text-xl font-semibold mb-2">Хостинг</h2> 
              <p className="mb-4 w-[200px] text-gray-700">
                Размещайте сайты на быстром хостинге с удобной панелью
                управления
              </p>
              <div className="flex mt-[100px] z-50 space-x-4">
                <button className="bg-blue-600 text-white py-2 px-4 rounded-full transition duration-200 hover:bg-blue-700">
                  Подробное
                </button>
                <button className="bg-[white] border hover:text-white border-blue-600 text-blue-600 py-2 px-4 rounded-full transition duration-200 hover:bg-blue-700">
                  Оформить
                </button>
              </div>
            </div>
            <img
              src={Second}
              alt="Хостинг"
              className="mt-[160px] sm:hidden w-[200px] h-[200px] absolute ml-[370px]"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SixSection;
