import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { FaFacebook, FaMailBulk, FaTelegram } from "react-icons/fa";
import "./Footer.css";
import android from "../../images/footer/playMarket.png";
import ios from "../../images/footer/appStore.png";
import call from "../../images/footer/call.png";

class Footer extends Component {
  render() {
    return (
      <div className="Footer">
        <Container>
          <div className="flexFooter">
            <div className="block">
              <h3>Как с нами связаться</h3>
              <div className="callCenter">
                <label className="font-[600] text-[black]">Call Center</label>
                <label className="flex items-center gap-[10px]">
                  <img src={call} alt="#" />
                  <p className="text-[27px] pt-[15px] sm:text-[23px] font-bold text-[#464646]">
                    44 600 60 60
                  </p>
                </label>
              </div>
              <div className="flex flex-col gap-[10px]">
                <label className="flex items-center gap-[15px]">
                  <FaMailBulk />
                  Info@babilon-t.com
                </label>
                <label className="flex items-center gap-[15px]">
                  <FaFacebook />
                  Написать Facebook
                </label>
                <label className="flex items-center gap-[15px]">
                  <FaTelegram />
                  Написать в Telegram Bot
                </label>
              </div>
            </div>
            <div className="block">
              <h3>Услуги</h3>
              <label className="uslug">
                <a className="text-[black] no-underline" href="/home">
                  Домашний Интернет{" "}
                </a>
              </label>
              <label className="uslug">
                <a className="text-[black] no-underline" href="/wimax">
                  Технология WiMAX
                </a>
              </label>
              <label className="uslug">
                <a className="text-[black] no-underline" href="/lte">
                  Технология LTE
                </a>
              </label>
              <label className="uslug">
                <a href="/tv" className="text-black no-underline">
                  IPTV - Телевидения
                </a>
              </label>
              <label className="uslug">
                <a href="ngn" className="text-black no-underline">
                  NGN Телефония
                </a>
              </label>
              <label className="uslug">
                <a href="/hosting" className="text-black no-underline">
                  Домин /Хостинг
                </a>
              </label>
            </div>
            <div className="block">
              <h3>Вавилон-Т</h3>
              <label className="bt">О компании</label>
              <label className="bt">Вакансии</label>
              <label className="bt">Акции </label>
              <label className="bt">Новости </label>
              <label className="bt">Контакты</label>
            </div>
            <div className="block app">
              <h3></h3>
              <div className="android">
                <img alt="#" src={android} />
              </div>
              <div className="ios">
                <img alt="#" src={ios} />
              </div>
            </div>
          </div>
        </Container>
        <hr />
        <div className="end">
          <label className="sm:text-[18px] sm:pl-[3%]">
            Политика кондифициальности
          </label>
          <label className="sm:text-[18px] sm:pl-[3%]">
            © Все права защищены
          </label>
        </div>
      </div>
    );
  }
}

export default Footer;
