import React, { useState, useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import './News.css';
import axios from 'axios';

import { URL } from '../../url';



const News = () => {
	const [tarifs, setTarifs] = useState([]);

	useEffect(() => {
		const fetchTarifs = async () => {
			try {
				const response = await axios.get(`${URL}/api/news`);
				console.log("Полученные данные:", response.data);
				setTarifs(response.data.slice(0, 3));
			} catch (error) {
				console.error(
					"Ошибка при получении данных",
					error.response ? error.response.data : error.message
				);
			}
		};
		fetchTarifs();
		}, []);

	return (
		<div className='News'>
			<Container>
				<div className='title'>
					<div><h3>Наши новости</h3></div>
					<div><a href='/news'><h4>Все статьи</h4></a></div>
				</div>
				<div className='cards'>
					{tarifs.map((item) => (
						<Card className='myCard' key={item.id}>
							<Card.Img className='img' variant="top" src={`${URL}${item.image}`} alt={item.title} />
							<Card.Body>
								<span className='date'>{item.date}</span>
								<Card.Title className='cardTitle'>{item.title}</Card.Title>
								<Card.Text className='cardText'>
									{item.description}
								</Card.Text>
							</Card.Body>
						</Card>
					))}
				</div>
			</Container>
		</div>
	);
}

export default News;
