import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import CryptoJS from "crypto-js";

// Function to get cities and fetch coordinates
const testGetCities = async () => {
  const baseUrl = "https://rps2.babilon-t.com";
  const path = "/get/map/city";
  const method = "GET";
  const timestamp = Math.floor(Date.now() / 1000).toString();
  const clientId = "babilon-web";
  const secretKey =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBUEkgS0VZIiwibmFtZSI6Imh0dHBzOi8vcnBzLmJhYmlsb24tdC5jb20vZ2V0L21hcC9jaXR5IiwiaWF0IjoxNTE2MjM5MDIyfQ.M30GnKKSjXSxx-RPGA5ctau6aBEjjTMoNMmDQAgUzv8";

  const normalizedPath = path.replace(/^\//, "");
  const normalizedMethod = method.toUpperCase();

  const dataToSign = [
    timestamp,
    clientId,
    normalizedMethod,
    normalizedPath,
    "", // пустая строка для GET запроса
  ].join("|");

  console.log("Client data to sign:", dataToSign);

  // Создаем HMAC SHA256 подпись с использованием CryptoJS
  const hash = CryptoJS.HmacSHA256(dataToSign, secretKey).toString(
    CryptoJS.enc.Hex
  );

  console.log("Client generated hash:", hash);
  console.log("Time :", timestamp);
  console.log("ClientID :", clientId);

  const headers = {
    "Content-Type": "application/json",
    "X-Timestamp": timestamp,
    "X-Client-ID": clientId,
    "X-Api-Version": "1.0",
    "X-Hash": hash,
  };
  console.log("Headers:", headers);
  try {
    const response = await fetch(baseUrl + path, {
      method: method,
      headers: headers,
      mode: "no-cors",
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server error:", errorText);
      return;
    }

    const result = await response.json();
    console.log("Response:", result);
  } catch (error) {
    console.error("Error:", error);
  }
};

const MapConnect = () => {
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [house, setHouse] = useState("");
  const [apartment, setApartment] = useState("");
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState([38.5598, 68.787]);
  const [showMap, setShowMap] = useState(false);
  const [error, setError] = useState(null);
  const [cities, setCities] = useState(["Душанбе", "Худжанд", "Бохтар"]);

  const cityCoordinates = {
    Душанбе: [38.5598, 68.787],
    Худжанд: [40.2896, 69.6192],
    Бохтар: [37.832, 68.7802],
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "city" && cityCoordinates[value]) {
      setCoordinates(cityCoordinates[value]);
    }

    if (name === "city") {
      setCity(value);
    } else if (name === "street") {
      setStreet(value);
    } else if (name === "house") {
      setHouse(value);
    } else if (name === "apartment") {
      setApartment(value);
    }
  };

  const handleCheck = () => {
    setError(null); //
    if (!city) {
      setError("Пожалуйста, выберите город.");
      return;
    }
    const newAddress = `${city}, ${street} ${house}, ${apartment}`;
    setAddress(newAddress);
    setShowMap(true);
  };

  // useEffect(() => {
  //   const fetchCities = async () => {
  //     const citiesResult = await testGetCities();
  //     if (citiesResult) {
  //       setCities(citiesResult); // Update with actual API response
  //     }
  //   };

  //   fetchCities();
  // }, []);

  useEffect(() => {
    testGetCities();
  }, []);
  return (
    <div className="mapBody">
      <style>{`
        .leaflet-container {
          height: 400px;
          width: 100%;
          margin-top: 20px;
        }
      `}</style>

      <Container>
        <div className="mapCard">
          <div className="mapTitle">
            <h1>Проверьте возможность подключения</h1>
          </div>
          <div className="blockInput">
            <div className="col-12 col-md-6 mb-3">
              <p>Укажите адрес</p>
              <select
                className="form-control"
                name="city"
                value={city}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Город
                </option>
                {cities.map((cityOption) => (
                  <option key={cityOption} value={cityOption}>
                    {cityOption}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-6 mb-3">
              <input
                type="text"
                name="street"
                value={street}
                onChange={handleInputChange}
                placeholder="Улица"
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <input
                type="text"
                name="house"
                value={house}
                onChange={handleInputChange}
                placeholder="Дом"
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <input
                type="text"
                name="apartment"
                value={apartment}
                onChange={handleInputChange}
                placeholder="Квартира"
                className="form-control"
              />
            </div>
            <div className="col-12 pt-3">
              <button onClick={handleCheck} className="btn btn-primary w-100">
                Проверить
              </button>
            </div>
          </div>

          {address && (
            <div className="finalAddress">
              <p>
                <i className="fas fa-map-marker-alt"></i> {address}
              </p>
            </div>
          )}

          {showMap && (
            <div className="mapContainer">
              <MapContainer
                center={coordinates}
                zoom={13}
                className="leaflet-container"
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={coordinates}>
                  <Popup>{address || "Выберите адрес"}</Popup>
                </Marker>
              </MapContainer>
            </div>
          )}

          {error && (
            <div className="error">
              <p>{error}</p>
            </div>
          )}

          <div className="footerText">
            <p className="pt-3">
              Введите свой адрес, чтобы проверить, доступен ли по нему домашний
              Интернет
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MapConnect;
