import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import './Iptv.css';
import vector from '../../images/iptv/Vector.png';
import tv from '../../images/iptv/TV.png';

class Iptv extends Component {
    render() {
        return (
            <div className='Iptv'>
                <Container>
                    <div className='myIptv'>
                        <div className='textTv'>
                            <h2>IPTV - телевидения</h2>
                            <span>Как прекрасно собраться с друзьями и близкими и включить любимый фильм или передачу! </span>
                            <div className='label sm:flex-col sm:gap-[10px]'>
                                <label className='flex items-center gap-[5px]'> <span> <img src={vector} /></span>Новые фильмы каждый день</label>
                                <label className='flex items-center gap-[5px]'> <span><img src={vector} /></span>Более 400 каналов </label>
                            </div>
                        </div>
                        <div className='Tv'>
                            <img src={tv} />
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default Iptv;