import React, { Component } from 'react';
import SelectedTarrif from '../Components/SelectedTarrif/SelectedTarrif';

class ConnectRequest extends Component {
    render() {
        return (
            <div>
                <SelectedTarrif/>
            </div>
        );
    }
}

export default ConnectRequest;