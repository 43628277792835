import React, { Component } from 'react';
import './Wimax.css';
import logo from '../images/wimax/wimax_logo.png';
import router from '../images/wimax/router_wimax.png';
import { Container } from 'react-bootstrap';
import AllTarrifsWimax from '../Components/AllTarrifsWimax/AllTarrifsWimax';
import SendRequest from '../Components/Tarrifs/Dushanbe/Wimax/SendRequest';
import Equipment from '../Components/Equipment/Equipment';
class Wimax extends Component {
    render() {
        return (
            <div className='wimax'>
                <div className='fonHeader'>
                    <Container>
                        <div className='flex'>
                            <img src={logo} alt='' />
                        <div className='headerText'>
                            <h4>Беспроводной</h4>
                            <h1>Иинтернет</h1>
                        </div>
                        <img className='imgRouter' src={router} alt=''/>
                        </div>
                    </Container>
                </div>
                <AllTarrifsWimax/>
                <SendRequest/>
                <div id='12'>
                <Equipment/>
                </div>
            </div>
        );
    }
}

export default Wimax;