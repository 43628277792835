import React, { Component } from "react";
import "./AllTarrifs.css";
import Dushanbe from "../Tarrifs/Dushanbe/Dushanbe";
import Optom from "../Tarrifs/Dushanbe/Optom";
import Turbo from "../Tarrifs/Dushanbe/Turbo";
import { Container } from "react-bootstrap";
class AllTarrifs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCity: "Душанбе",
      selectedTariff: "Домашний интернет",
    };
  }

  handleCityChange = (event) => {
    const selectedCity = event.target.value;
    this.setState({
      selectedCity,
      selectedTariff: "Домашний интернет",
    });
  };

  handleTariffChange = (tariff) => {
    this.setState({ selectedTariff: tariff });
  };

  getTariffMessage = () => {
    const { selectedCity, selectedTariff } = this.state;
    if (selectedTariff === "Домашний интернет") {
      if (selectedCity === "Душанбе") {
        return <Dushanbe />;
      }
    } else if (selectedTariff === "Годовой пакет") {
      return <Optom />;
    } else if (selectedTariff === "Турбо скорость") {
      return <Turbo />;
    }
    return "";
  };
  render() {
    const { selectedCity, selectedTariff } = this.state;
    const tariffs = ["Домашний интернет", "Годовой пакет", "Турбо скорость"];
    const cities = ["Душанбе", "Худжанд", "Бохтар"];
    return (
      <>
        <Container>
          <div
            style={{
              marginTop: "20px",
              fontWeight: "normal",
              marginBottom: "20px",
            }}
          >
            Интернет / {selectedTariff} /{" "}
            <span style={{ color: "#9A9CA5" }}>Тарифы</span>
          </div>
          <div>
            <h2>Выберите тариф для подключения</h2>
          </div>
          <div className="allSelect">
            <div>
              {tariffs.map((tariff) => (
                <button
                  className="button"
                  key={tariff}
                  onClick={() => this.handleTariffChange(tariff)}
                  style={{
                    fontWeight: selectedTariff === tariff ? "400" : "normal",
                    borderBottom:
                      selectedTariff === tariff ? "4px solid #0593e5" : "none",
                    margin: "5px",
                  }}
                >
                  {tariff}
                </button>
              ))}
            </div>
            <div className="select">
              <label htmlFor="city-select">Город подключения: </label>
              <select
                id="city-select"
                value={selectedCity}
                onChange={this.handleCityChange}
                style={{ margin: "5px" }}
              >
                {cities.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {selectedTariff && <p>{this.getTariffMessage()}</p>}
        </Container>
      </>
    );
  }
}
export default AllTarrifs;
