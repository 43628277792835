import React, { Component } from 'react';
import './ConnectStages.css';
import { Button, Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import connect1 from '../../images/connect/connect1.png'
import connect2 from '../../images/connect/connect2.png'
import connect3 from '../../images/connect/connect3.png'
import connect4 from '../../images/connect/connect4.png'
import connect5 from '../../images/connect/connect5.png'
class ConnectStages extends Component {
    render() {
        return (
            <div className='ConnectFon'>
                <Container>
                    <div className='title'>
                        <h2>Этапы подключение</h2>
                    </div>

                    <div className='cards grid grid-cols-5 pb-[50px] gap-x-[20px] md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 sm:grid-cols-1 gap-[20px] pt-[20px]'>
                        <Card className='myCard'>
                            <label >1</label>
                            <Card.Img className='img' src={connect1} />
                            <Card.Body>
                                <Card.Title className='cardTitle' >Оставьте заявку</Card.Title>
                                <Card.Text className='cardText first'>
                                    Просто заполните форму
                                </Card.Text>
                                <div className='link'>

                                    <a href='#/'>Подать заявку</a>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='myCard'>
                            <label >2</label>
                            <Card.Img className='img' src={connect2} />
                            <Card.Body>
                                <Card.Title className='cardTitle' >Дождитесь звонка</Card.Title>
                                <Card.Text className='cardText'>
                                    Мы свяжемся с вами с 8:00 до 17:00 и все подтвердим.
                                </Card.Text>
                                
                            </Card.Body>
                        </Card>
                        <Card className='myCard'>
                            <label >3</label>
                            <Card.Img className='img' src={connect3} />
                            <Card.Body>
                                <Card.Title className='cardTitle' >Обработка </Card.Title>
                                <Card.Text className='cardText three'>
                                    Ваша квартира будет подключена в порядке очереди в течении 3 дней
                                </Card.Text>
                                <div className='link'>

                                    <a href='#'>Фаст подключения</a>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='myCard'>
                            <label >4</label>
                            <Card.Img className='img' src={connect4} />
                            <Card.Body>
                                <Card.Title className='cardTitle' >Установка</Card.Title>
                                <Card.Text className='cardText'>
                                    В удобное вам время  наши специалисты привезут договор, подключат интернет и настроят оборудование.
                                </Card.Text>
                                
                            </Card.Body>
                        </Card>
                        <Card className='myCard'>
                           
                            <Card.Img className='img5' src={connect5} />
                            <Card.Body>
                                <Card.Text className='finalText '>
                                    Успешно
                                    подключено
                                </Card.Text>
                                
                            </Card.Body>
                        </Card>

                    </div>
                </Container>
            </div>
        );
    }
}

export default ConnectStages;